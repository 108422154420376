import './shared/rails';

// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/client-side-validations/src';
// eslint-disable-next-line import/no-unresolved
import '@client-side-validations/simple-form/src/index.bootstrap4';

// Import Bootstrap
import * as bootstrap from 'bootstrap';

// Import jQuery
import './jquery';

// import Stimulus controllers
import './controllers/thecarbuyingshop';

// import './admin/get-make-logo.js.erb';

// Import components
import './shared/scroll-to';
import './shared/lazy-load';
import { Datepicker } from './shared/datepicker';
import { initAllTooltips } from './shared/util';
import { Form } from './shared/form';

const loadNonCriticalFeaturesWhenIdle = () => {
  // These can load after the page is interactive
  const rIC = 'requestIdleCallback' in window ? requestIdleCallback : setTimeout;
  const options = 'requestIdleCallback' in window ? {} : 5000;
  rIC(() => {
    Promise.all([import('./shared/cookie-consent'), import('./shared/browser-update')]).catch(
      console.error
    );
  }, options);
};

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', loadNonCriticalFeaturesWhenIdle);
} else {
  loadNonCriticalFeaturesWhenIdle();
}

document.addEventListener('DOMContentLoaded', () => {
  // initialize tooltips
  const tooltips = initAllTooltips();

  // initialize date/time pickers
  if (document.querySelectorAll('.js-date-picker').length) {
    const dateTimePickers = document.querySelectorAll('.js-date-picker');
    if (dateTimePickers.length) {
      [...dateTimePickers].map((dateTimePicker) => new Datepicker(dateTimePicker));
    }
  }

  // initialize forms
  if (document.querySelectorAll('.js-form').length) {
    const forms = document.querySelectorAll('.js-form');
    if (forms.length) {
      [...forms].map((form) => new Form(form));
    }
  }
});
